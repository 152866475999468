import { Line } from "react-chartjs-2";

const chartData: (React.ComponentProps<typeof Line>)["data"] = {
  labels: ['JAN-24', 'FEB-24', 'MAR-24', 'APR-24', 'MAY-24', 'JUN-24', 'JUL-24', 'AUG-24'],
  datasets: [
    {
      data: [32945892675, 38076170830, 54067305254, 62710330980, 69813448797, 75645901217, 82134869248, 88962249792],
      borderColor: '#62C759',
      borderWidth: 2,
      backgroundColor: '#26433A',
      pointStyle: 'rect',
      pointBorderWidth: 6,
      pointHoverBorderWidth: 20,
      pointHitRadius: 20,
      fill: true,
    }
  ],
};

export default chartData;
